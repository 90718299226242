
export function parseSqlDate( date ) {
    const offset = new Date().getTimezoneOffset()
    date = new Date(date)
    date = new Date(date.getTime() - (offset*60*1000))
    const dateArr = date.toISOString().split(/[- : T]/);
    return dateArr[2] + '/' + dateArr[1] + '/' + dateArr[0] + ' à ' + dateArr[3] + ':' + dateArr[4]
}

export function parseSqlDateNoHour( date ) {
    const offset = new Date().getTimezoneOffset()
    date = new Date(date)
    date = new Date(date.getTime() - (offset*60*1000))
    const dateArr = date.toISOString().split(/[- : T]/);
    return dateArr[2] + '/' + dateArr[1] + '/' + dateArr[0]
}

export function getDaysArray (start, end) {
    const daylist = [];
    var dateEnd = new Date(end)
    var tzoffset = (new Date(end)).getTimezoneOffset() * 60000; //offset in milliseconds
    for(var dt=new Date(start); dt <= dateEnd; dt.setTime(dt.getTime() + ( 86400000 + tzoffset ))){
        const offset = dt.getTimezoneOffset()
        dt = new Date(dt.getTime() - (offset*60*1000))
        daylist.push(dt.toISOString().split('T')[0]);
    }
    return daylist;
}

var d = new Date()
d.setHours(0,0,0,0)
var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
var localISOTime = (new Date(d - tzoffset)).toISOString().slice(0, -8);

export const todayMidnight = localISOTime
export const todayDate = new Date().toISOString().split("T")[0];
export const todayEightAm = (new Date(d - tzoffset + 28800000)).toISOString().slice(0, -8);
export const todaySixPm = (new Date(d - tzoffset + 64800000)).toISOString().slice(0, -8);