import React, { useState } from 'react'
import { Label, Button, Modal, Checkbox } from "flowbite-react";
import { useNavigate } from 'react-router-dom'
import { todayDate } from '../../../utils/utils';


const AvailModal = ({ modalState, closeModal }) => {
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [weekends, setWeekends] = useState(false);
    const navigate = useNavigate()

    const addAvail = async (e) => {
        e.preventDefault();
        console.log(Date(startDate) +" "+ Date(endDate))
        if( startDate && !endDate ) {
            alert("Veuillez entrer une date de fin")
            return
        } 
        if( new Date(startDate) > new Date(endDate) ) {
            alert("La date de début ne peut pas être après la date de fin")
            return
        } 
        
        const response = await fetch('https://api.planileo.fr/planning/addAvailability', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                startDate: startDate,
                endDate: endDate,
                weekends: weekends
            }),
        });
        if (response.status === 401) {
            alert('Votre session a expiré. Veuillez vous reconnecter.')
            navigate('/login', { replace: true })
        } else if (response.status !== 200) {
            alert('Une erreur est survenue')
        } else {
            const result = await response.json();
            if (result.success === true) {
                //getUnavails()
                closeModal()
                // reset
                window.location.reload()
            } else {
                alert(result.message)
            }
        }
    }

    return (

        <Modal show={modalState} onClose={() => closeModal()}>
            <Modal.Header>Ajouter une disponibilité</Modal.Header>
            <Modal.Body>
                <div className="space-y-6">
                    <div>
                        <p className="font-small text-gray-600">Vous serez indiqué comme disponible pour les organismes sur ces dates</p>
                    </div>
                    <form onSubmit={addAvail}>
                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="startDate" value="Date de début" />
                            </div>
                            <input type="date" min={todayDate} className="block w-full border disabled:cursor-not-allowed disabled:opacity-50 border-gray-300 bg-gray-50 text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 p-2.5 text-sm rounded-lg"
                                id="startDate"
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </div>
                        <div className="mt-2">
                            <div className="mb-2 block">
                                <Label htmlFor="endDate" value="Date de fin" />
                            </div>
                            <input type="date" min={startDate} className="block w-full border disabled:cursor-not-allowed disabled:opacity-50 border-gray-300 bg-gray-50 text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 p-2.5 text-sm rounded-lg"
                                id="endDate"
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </div>
                        <div className="flex items-center mt-2">
                            <Checkbox id="weekends" onChange={(e) => setWeekends(e.target.checked)}/>
                            <Label className="ml-2" htmlFor="weekends">Inclure les week-ends</Label>
                        </div>
                        <div className="w-full mt-4">
                            <Button type="submit">Ajouter</Button>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    );
}
export default AvailModal;